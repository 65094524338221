.section{
    padding-top: 40px;
    padding-bottom: 40px;
}
.main-heading{
    color: green;
    font-size: 26px;
}
.underline{
    height: 04px;
    width: 4rem;
    background-color: red;
    margin-top: 10px;
    margin-bottom: 10px;
}
